.modal {
  background-color: var(--black-5);
  .modal-dialog {
    .modal-content {
      background-color: var(--bgModal);
      border: 2px solid var(--purpleColor-3);
      border-radius: 0.937rem;
      .modal-body {
        position: relative;
        .btn-close-modal {
          position: absolute;
          top: 1rem;
          right: 1rem;
        }
      }
    }
  }
  &.vessel-modal-bg {
    .modal-dialog {
      max-width: 900px;
      .modal-content {
        background-image: url('../../../assets/images/vessel-modal-bg.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center bottom;
      }
    }
  }
}
