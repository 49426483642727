.game-limit-modal {
  .modal-dialog {
    max-width: 476px;
    .modal-body {
      padding-left: 1.625rem;
      padding-right: 1.625rem;
      @media only screen and (max-width: 768px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      .game-limit-title {
        h5 {
          border-color: var(--purpleExDark) !important;
          font-family: 'Poppins', sans-serif;
        }
      }

      .fairness-text {
        color: var(--white-4);
      }

      .top-bets-item {
        background-color: var(--bgPrimary);
        border-radius: 0.625rem;
        padding: 1rem;

        &:not(:last-child) {
          margin-bottom: 0.625rem;
        }

        .top-bets-result-box {
          background-color: var(--dropdownMenuBg);
          padding: 0.64rem 1rem;
          border-radius: 0.375rem;

          .top-bets-result-label {
            color: var(--white-5);
          }

          &:not(:last-child) {
            margin-bottom: 0.625rem;
          }
        }
      }
      .gold-coin-section {
        @media only screen and (max-width: 768px) {
          max-height: 330px;
          overflow: auto;
        }
      }
    }
  }
}
