.how-to-play-modal {
  .modal-dialog {
    max-width: 900px;

    .modal-body {
      padding: 0 3.75rem 0 3.75rem;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 20%;
        background: var(--sliderOverlayGradient180deg);
        pointer-events: none;
        border-radius: 0 0 0.8rem 0.8rem;
      }

      .how-to-play-title {
        h5 {
          font-size: 2rem;
          font-weight: 600;
          font-family: 'poppins', sans-serif;
          margin-top: 3.125rem;
        }

        p {
          font-family: 'poppins', sans-serif;
          margin-bottom: 2.5rem;
        }
      }

      .how-to-play-content-body {
        min-height: 100px;
        overflow: auto;
        height: calc(100vh - 400px);
        @media only screen and (max-width: 768px) {
          height: calc(100vh - 200px);
        }

        &::-webkit-scrollbar {
          width: 0;
        }

        .game-content-box {
          .game-content-items {
            border-radius: 0.938rem;
            background-color: var(--gameContentBg);
            padding: 1.5rem;
            font-family: 'poppins', sans-serif;
            font-weight: normal;
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
            ul {
              margin: 0;
              li {
                &:not(:last-child) {
                  margin-bottom: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
