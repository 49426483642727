.provably-fair-modal {
  .modal-dialog {
    max-width: 560px;
    .provably-fair-title {
      h5 {
        border-color: var(--purpleExDark) !important;
        font-family: 'Poppins', sans-serif;
      }
    }
    .fairness-text {
      color: var(--white-4);
    }
  }
}
