.my-bets-section {
  .table-responsive {
    min-height: 100px;
    max-height: calc(100vh - 303px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    .default-table {
      thead {
        position: sticky;
        top: 0;
        z-index: 999;
        th {
          background-color: var(--bgSecondary);
        }
      }
      .date-td {
        opacity: 0.4;
      }
    }
  }
}
