.btn {
  font-size: 0.938rem;

  &.h-custom-36 {
    padding: 0.3rem 0.75rem;
  }

  &.btn-primary {
    background: var(--primaryGradient);
    border: 0;
    border: 2px solid transparent;
    background-origin: border-box;

    &:hover:enabled {
      border-color: var(--secondary);
    }
  }

  &.btn-outline-primary {
    background: var(--purpleCircleBtn);
    border: 2px solid var(--primaryHover);
    color: var(--white);

    &:hover:enabled {
      background: var(--primaryGradient);
      border-color: transparent;
      background-origin: border-box;
    }
  }

  &.btn-secondary {
    border: 2px solid var(--primaryHover);
    background: var(--primaryHover);
    font-weight: 500;

    &:hover:enabled {
      border-color: var(--secondary);
      background: var(--primaryHover);
    }
  }

  &.btn-success {
    border: 2px solid var(--greenChecked);
    background: var(--greenChecked);
    font-weight: 500;

    &:hover:enabled {
      border-color: var(--greenChecked);
      background: var(--greenChecked);
      opacity: 0.9;
    }
  }

  &.btn-outline-secondary {
    color: var(--white);
    border: 2px solid var(--primary);
    background: transparent;

    &:hover:enabled {
      border-color: var(--secondary);
      background: var(--primaryHover);
    }
  }

  &.btn-danger {
    color: var(--white);
    border: 2px solid var(--dangerIconBg);
    background: var(--dangerIconBg);
    font-weight: 500;
  }

  &.btn-static {
    border: 2px solid var(--primary);
    background: transparent;

    &:hover:enabled {
      border: 2px solid var(--primary);
      background: transparent;
    }
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: all;

    &:hover:enabled {
      background: unset;
      border-color: var(--primary);
    }
  }

  // plinko game buttons
  &.btn-plinko-primary {
    background: var(--plinkoBtnPrimary);
    color: var(--white-4);
    border: 2px solid transparent;
    background-origin: border-box;
    min-height: 40px;

    &:not(.active):hover:enabled {
      border-color: var(--borderPinkColor);
      color: var(--white);
    }

    &.active:enabled {
      background: var(--plinkoBtnPrimaryHover);
      color: var(--white);
      background-origin: border-box;
    }
  }

  &.btn-gc-currency {
    background: linear-gradient(180deg, var(--gc-currency01) 0%, var(--gc-currency02) 100%);
    color: var(--white);
    box-shadow: 0px 0px 10px 0px var(--gc-currency01) inset;
    border: 2px solid var(--gc-currency01);
    min-height: 40px;

    &:not(.active):hover:enabled {
      background: linear-gradient(0deg, var(--gc-currency01) 0%, var(--gc-currency02) 100%);
    }

    &:first-child:active {
      color: var(--white);
      background: linear-gradient(180deg, var(--gc-currency01) 0%, var(--gc-currency02) 100%);
    }

    &.active:enabled {
      color: var(--white);
      background: linear-gradient(180deg, var(--gc-currency01) 0%, var(--gc-currency02) 100%);
    }
  }

  &.btn-sc-currency {
    background: linear-gradient(180deg, var(--sc-currency01) 0%, var(--sc-currency02) 100%);
    color: var(--white);
    box-shadow: 0px 0px 10px 0px var(--sc-currency01) inset;
    border: 2px solid var(--sc-currency01);
    min-height: 40px;

    &:not(.active):hover:enabled {
      background: linear-gradient(0deg, var(--sc-currency01) 0%, var(--sc-currency02) 100%);
    }

    &:first-child:active {
      color: var(--white);
      background: linear-gradient(180deg, var(--sc-currency01) 0%, var(--sc-currency02) 100%);
    }

    &.active:enabled {
      color: var(--white);
      background: linear-gradient(180deg, var(--sc-currency01) 0%, var(--sc-currency02) 100%);
    }
  }

  &.btn-plinko-success {
    background: linear-gradient(180deg, var(--plinkoBtnGreen01) 0%, var(--plinkoBtnGreen02) 100%);
    color: var(--white);
    box-shadow: 0px 0px 10px 0px var(--plinkoShadowGreen) inset;
    border: 2px solid var(--plinkoBtnGreen01);
    min-height: 40px;

    &:not(.active):hover:enabled {
      background: linear-gradient(0deg, var(--plinkoBtnGreen01) 0%, var(--plinkoBtnGreen02) 100%);
    }

    &.active:enabled {
      background: linear-gradient(180deg, var(--plinkoBtnGreen01) 0%, var(--plinkoBtnGreen02) 100%);
    }
  }
}
