header {
  position: relative;
  z-index: 1002;

  @media screen and (max-width: 767px) {
    position: sticky;
    top: 0;
    background-color: var(--bgPrimary);
  }

  nav {
    padding: 1.125rem 0 !important;

    @media screen and (max-width: 767px) {
      padding: 0.25rem 0 !important;
    }

    .navbar-brand {
      cursor: pointer;

      img {
        filter: drop-shadow(0px 0px 10px rgba(181, 25, 255, 0.302));

        @media screen and (max-width: 767px) {
          max-width: 100px;
        }
      }
    }

    .navbar-wallet-btn {
      min-width: 204px;

      @media screen and (max-width: 767px) {
        min-width: unset;
        width: 100%;
      }
    }

    .btn {
      min-height: 42px;
    }

    .setting-dropdown {
      .dropdown-menu {
        background-color: var(--dropdownMenuBg);
        outline: 2px solid var(--purpleColor-3);
        outline-offset: -2px;
        min-width: 280px;
        border-radius: 1rem;
        padding: 1rem;
        z-index: 1002;

        &.show {
          animation: 500ms ease-in slidein;

          @media screen and (max-width: 767px) {
            position: fixed;
            right: 0;
            left: 0;
            top: 50px;
            max-width: 310px;
            margin: auto;
            animation: none;
          }
        }

        .switch-settings {
          background-color: var(--flipCoinGrey400);
          padding: 0 1rem;
          border-radius: 0.625rem;
          list-style: none;

          li {
            padding: 0 !important;

            &:last-child {
              .dropdown-item {
                border-bottom: 0;
              }
            }
          }
        }

        li {
          &:not(:last-child) {
            .dropdown-item {
              border-bottom: 1px solid var(--flipCoinGrey350);
            }
          }

          .dropdown-item {
            padding: 0.75rem 0;
            color: var(--white);
            font-weight: 500;

            &:hover,
            &:focus,
            &:visited {
              background-color: transparent;
              color: var(--white);
              outline: 0;

              svg {
                stroke: var(--white) !important;
              }
            }

            .form-check-input[type=checkbox] {
              background-color: var(--flipCoinHeaderBg);
              border-color: var(--flipCoinHeaderBg);

              &:checked {
                background-color: var(--greenChecked);
                border-color: var(--greenChecked);
              }
            }
          }

          &:not(:first-child) {
            padding: 0 0.8rem;
          }
        }
      }

      & [aria-expanded=false]+.dropdown-menu {
        @media screen and (min-width: 767px) {
          animation: 500ms ease-out slideout;
        }
      }

      .setting-user-icon {
        width: 3rem;
        height: 3rem;
        border-radius: 50em;
        color: var(--white);
        font-weight: 700;
        background-color: var(--userIconBg);
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn-close-modal {
        margin-top: -12px;
        margin-right: -6px;
      }
    }

    .navbar-nav {
      .nav-item {
        &:first-child {
          @media screen and (max-width: 767px) {
            min-width: 100px;
            max-width: 167px;
            width: 100%;
          }
        }
      }
    }
  }

  &.plinko-game-header {
    background-color: transparent;

    nav {
      padding-top: 0.6rem !important;
      padding-bottom: 0.6rem !important;
      border-bottom: 1px solid var(--white-1);
      font-family: 'panton', sans-serif;

      @media screen and (min-width: 992px) {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
      }

      .btn {
        min-width: 42px;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        background: var(--plinkoHeaderBtnBg);
        border-color: var(--plinkoHeaderBtnBg);

        &:hover {
          border-color: var(--borderPinkColor) !important;
          background: var(--plinkoHeaderBtnBg) !important;
        }

        &.navbar-wallet-btn {
          min-width: 100px;
          max-width: 200px;
          padding-right: 0.625rem;
          padding-left: 0.625rem;
          font-size: 0.875rem;

          @media screen and (max-width: 374.98px) {
            font-size: 0.75rem;

            .navbar-wallet-currency-icon {
              display: none;
            }
          }

          @media screen and (min-width: 768px) {
            font-size: 1rem;
            min-width: 185px;
          }
        }

        &.navbar-music-btn {
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            top: 6px;
            right: 6px;
            background-color: var(--green);
          }

          &.muted {
            svg {
              fill: var(--plinkoMusicMuted);
            }

            &::before {
              background-color: var(--plinkoMusicMuted);
            }
          }
        }

        &.btn-close-modal {
          min-width: 26px;
          width: 26px;
          height: 26px;
          border-color: var(--plinkoSelectBorder);
        }
      }

      .navbar-wallet-btn-item {
        @media screen and (max-width: 767.98px) {
          flex-grow: 1 !important;
        }
      }

      .setting-dropdown {
        .setting-user-icon {
          border-radius: .625rem;
          background: var(--plinkoBtnPrimaryHover);
          background-origin: border-box;
        }

        .setting-user-name {
          font-weight: 600;
        }

        .dropdown-menu {
          background: var(--PlinkoSidebarBg);
          outline: 2px solid var(--plinkoSelectBorder);
        }
      }

    }

    .navbar-nav {
      .nav-item {
        &:first-child {
          @media screen and (max-width: 767px) {
            min-width: 100px;
            max-width: unset;
            width: unset;
          }
        }
      }
    }

    &.empty-header {
      // height: 55px;

      nav {
        border-bottom: none;
      }
    }
  }

  &.flip-coin-game-header {
    background-color: transparent;
    position: static !important;

    nav {
      padding: 0.625rem !important;
      font-family: 'panton', sans-serif;
      position: relative;
      z-index: 1111;

      .custom-container-fluid {
        border-radius: 1rem;
        padding-top: 0.625rem !important;
        padding-bottom: 0.625rem !important;
        background-color: var(--flipCoinHeaderBg);
      }

      .navbar-brand {
        img {
          max-height: 42px;
        }
      }

      .btn {
        min-width: 42px;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        background: var(--flipCoinGrey400);
        border-color: var(--flipCoinGrey400);

        &:hover {
          background: var(--flipCoinGrey400) !important;
          border-color: var(--flipCoinGreenMain) !important;
        }

        &.navbar-wallet-btn {
          min-width: 100px;
          max-width: 200px;
          padding-right: 0.625rem;
          padding-left: 0.625rem;
          font-size: 0.875rem;

          @media screen and (max-width: 374.98px) {
            font-size: 0.75rem;
          }

          .navbar-wallet-currency-icon {
            @media screen and (max-width: 374.98px) {
              display: none;
            }
          }

          .navbar-balance-label {
            max-width: 95px;

            @media screen and (max-width: 340.98px) {
              max-width: 70px;
            }
          }

          @media screen and (min-width: 768px) {
            font-size: 1rem;
            min-width: 185px;
          }
        }

        &.navbar-music-btn {
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            top: 6px;
            right: 6px;
            background-color: var(--green);
          }

          svg {
            fill: var(--white);
          }

          &.muted {
            svg {
              fill: var(--white-5);
            }

            &::before {
              background-color: var(--white-5);
            }
          }
        }

        &.btn-close-modal {
          min-width: 26px;
          width: 26px;
          height: 26px;
          border-color: var(--plinkoSelectBorder);
        }
      }

      .navbar-wallet-btn-item {
        @media screen and (max-width: 767.98px) {
          flex-grow: 1 !important;
        }
      }

      .setting-dropdown {
        .setting-user-icon {
          border-radius: .625rem;
          background: linear-gradient(180deg, var(--plinkoBtnGreen01) 0%, var(--plinkoBtnGreen02) 100%);
          background-origin: border-box;
        }

        .setting-user-name {
          font-weight: 600;
        }

        .dropdown-menu {
          background: var(--flipCoinHeaderBg);
          outline: 2px solid var(--flipCoinHeaderBg);
        }
      }

    }

    .navbar-nav {
      .nav-item {
        &:first-child {
          @media screen and (max-width: 767px) {
            min-width: 100px;
            max-width: unset;
            width: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {

  body:has(.setting-dropdown .dropdown-menu.show) #root,
  body:has(.setting-dropdown .dropdown-menu.show) header {
    &:after {
      content: '';
      background-color: var(--black-8);
      position: fixed;
      inset: 0;
      z-index: 888;
    }
  }

  body:has(.setting-dropdown .dropdown-menu.show) header {
    &:after {
      bottom: auto;
      height: 66px;
    }
  }
}

@keyframes slidein {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideout {
  0% {
    transform: translateX(-80%);
    display: block;
    opacity: 1;
  }

  70% {
    transform: translateX(20%);
    display: none;
    opacity: 0;
  }
}
