:root {
  // colors variables
  --white: #ffffff;
  --white-1: rgba(255, 255, 255, 0.1);
  --white-4: rgba(255, 255, 255, 0.4);
  --white-5: rgba(255, 255, 255, 0.5);
  --black: #000000;
  --black-25: rgba(0, 0, 0, 0.25);
  --black-5: rgba(0, 0, 0, 0.5);
  --black-8: rgba(0, 0, 0, 0.8);
  --primary: #7B1DDA;
  --primaryHover: #540E9B;
  --secondary: #C83FEB;
  --bgPrimary: #1E0A2A;
  --bgOutlinePrimary: #3A1A4E;
  --bgSecondary: #291238;
  --tableRowBg: #3D214F;
  --tableRowBorder: #4D2567;
  --purpleColor: #8A2FB6;
  --purpleColor-3: rgba(137, 47, 182, 0.3);
  --purpleExDark: #391A4E;
  --lightPurpleColor: #9633FA;
  --orange: #E58808;
  --golden: #FFC700;
  --lightBlue: #4768E8;
  --green: #2BFD3B;
  --greenChecked: #27B81F;
  --bgModal: #281137;
  --dropdownMenuBg: #2B123B;
  --userIconBg: #BC3C7A;
  --dangerIconBg: #C60E3A;
  --toasterBg: #BB1E43;
  --inputBorder: #321644;
  --gameContentBg: #321A42;
  --lightBorder: rgba(77, 36, 103, 0.4);
  --purpleCircleBtn: #3E0973;
  // gradient variables
  --primaryGradient: linear-gradient(114.49deg, #8700C7 31.29%, #E06447 81.89%, #FFBF19 99.86%);
  --primaryHoverGradient: linear-gradient(0deg, #8700C7 31.29%, #E06447 81.89%, #FFBF19 99.86%);
  --sliderOverlayGradient: linear-gradient(270deg, #1E0A2A 0%, rgba(30, 10, 42, 0) 100%);
  --sliderOverlayGradient180deg: linear-gradient(180deg, rgba(40, 17, 55, 0) 0%, #1A0825 100%);
  --gameControlHeadGradient: linear-gradient(180deg, rgba(50, 24, 67, 0) 0%, #321843 100%);
  border-bottom: 1px solid #3C1C51;
  border-radius: 15px 15px 0px 0px;
  --bottomModalTableOverlay: linear-gradient(180deg, rgba(43, 18, 59, 0) 0%, #2B123B 100%);
  // plinko color var
  --PlinkoSidebarBg: #2F0E57;
  --plinkoBtnPrimary: #401982;
  --bc-currency: #0DE83D;
  --borderPinkColor: #BE3EFA;
  --plinkoSwitchThumbnail: #5E29BB;
  --plinkoSwitchBg: #17062C;
  --plinkoInputBg: #220B3E;
  --plinkoBtnGreen01: #45E90C;
  --plinkoBtnGreen02: #02472E;
  --plinkoSelectBorder: #4B2280;
  --plinkoTableBg: #271142;
  --plinkoHeaderBtnBg: #220B47;
  --plinkoMusicMuted: #4D2198;
  --plinkoSettingBtnBorder: #7340FF;
  --plinkoHrLine: #3F1670;
  // label colors
  --plinkoOrange800: #FC6F1F;
  --plinkoBlue700: #346DFF;
  --plinkoBlue300: #3CBCB4;
  --plinkoPurple500: #B122F4;
  --plinkoRed800: #C82020;

  --plinkoShadowGreen: #67FF4F;
  --plinkoBtnPrimaryHover: linear-gradient(180deg, #BE3EFA 0%, #6217AC 82.61%);

  // flip coin game color var
  --flipCoinHeaderBg: #1C1F2E;
  --flipCoinGrey200: #515671;
  --flipCoinGrey300: #373B4F;
  --flipCoinGrey350: #36394A;
  --flipCoinGrey400: #2A2D3E;

  --flipCoinGreenMain: #3DF444;

  --flipCoinPurple400: #6A37FF;
  --flipCoinPurple600: #332B87;
  --flipCoinPurple700: #2B148C;

  --flipCoinOrange300: #F6B100;

  // blackjack game color var
  --blackjackPurple600: #A635FF;
  --blackjackPurple700: #8B27F0;
  --blackjackYellow400: #FDDB63;

  // pixel value
  --roundedCommon: 0.938rem;

  //GC and SC coin
  --gc-currency01: #F9C611;
  --gc-currency02: #574402;
  --sc-currency01: #0de83d;
  --sc-currency02: #034f14;
}
